// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-vgc-how-to-use-kingdra-in-series-8-js": () => import("./../../../src/pages/blog/vgc/how-to-use-kingdra-in-series-8.js" /* webpackChunkName: "component---src-pages-blog-vgc-how-to-use-kingdra-in-series-8-js" */),
  "component---src-pages-blog-vgc-nut-cracker-how-to-beat-nut-core-in-pokemon-vgc-js": () => import("./../../../src/pages/blog/vgc/nut-cracker-how-to-beat-nut-core-in-pokemon-vgc.js" /* webpackChunkName: "component---src-pages-blog-vgc-nut-cracker-how-to-beat-nut-core-in-pokemon-vgc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-echo-pride-midori-turtle-join-dubwool-js": () => import("./../../../src/pages/news/echo-pride-midori-turtle-join-dubwool.js" /* webpackChunkName: "component---src-pages-news-echo-pride-midori-turtle-join-dubwool-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-profile-professorragna-js": () => import("./../../../src/pages/profile/professorragna.js" /* webpackChunkName: "component---src-pages-profile-professorragna-js" */),
  "component---src-pages-tournaments-kirisame-daycare-team-dubwool-shiny-tour-js": () => import("./../../../src/pages/tournaments/kirisame-daycare-team-dubwool-shiny-tour.js" /* webpackChunkName: "component---src-pages-tournaments-kirisame-daycare-team-dubwool-shiny-tour-js" */),
  "component---src-pages-tournaments-team-dubwool-holiday-clash-qualifier-js": () => import("./../../../src/pages/tournaments/team-dubwool-holiday-clash-qualifier.js" /* webpackChunkName: "component---src-pages-tournaments-team-dubwool-holiday-clash-qualifier-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

